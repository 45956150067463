export const USER_STORAGE = "jpBHjJNW1ONux3sTZeOdEkySJWUPOYfU";

export const POURCENTAGE_ASSURANCE_DUREE = {
  3: 26,
  6: 51,
};
export const TWO_FACTOR_AUTHENTICATION_TIMER = 180;

export const TYPES_INTERMEDIAIRE = {
  AGENT_GENERAL: "AGENT_GENERAL",
  COURTIER: "COURTIER",
};

export const USERS_ROLE_ENUMS = {
  ROOT_ADMIN: "ROOT_ADMIN",
  POOL_USER: "POOL_USER",
  COMPANY_USER: "COMPANY_USER",
  COURTIER_USER: "COURTIER_USER",
  AGENT_GENERAL_USER: "AGENT_GENERAL_USER",
  COMPANY_AGENCY_USER: "COMPANY_AGENCY_USER",
  INTERMEDIAIRE_AGENCY_USER: "INTERMEDIAIRE_AGENCY_USER",
};

export const USERS_BASE_ROUTES = {
  ROOT_ADMIN: "pool",
  POOL_USER: "pool",
  COMPANY_USER: "company",
  COURTIER_USER: "intermediaire",
  AGENT_GENERAL_USER: "intermediaire",
  COMPANY_AGENCY_USER: "agency",
  INTERMEDIAIRE_AGENCY_USER: "agency",
};

export const USERS_ROLE_ENUMS_OPTIONS = [
  {
    key: "DEFAULT",
    text: "Choisir...",
    value: "",
  },
  {
    key: USERS_ROLE_ENUMS.ROOT_ADMIN,
    text: "Super Admnistrateur",
    value: USERS_ROLE_ENUMS.ROOT_ADMIN,
  },
  {
    key: USERS_ROLE_ENUMS.POOL_USER,
    text: "Utilisateur du pool",
    value: USERS_ROLE_ENUMS.POOL_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COMPANY_USER,
    text: "Utilisateur de compagnie",
    value: USERS_ROLE_ENUMS.COMPANY_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COURTIER_USER,
    text: "Utilisateur Courtier",
    value: USERS_ROLE_ENUMS.COURTIER_USER,
  },
  {
    key: USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
    text: "Utilisateur Agent Général",
    value: USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
  },
  {
    key: USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER,
    text: "Utilisateur Courtier/Agent Général d'Agence",
    value: USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COMPANY_AGENCY_USER,
    text: "Utilisateur Bureau Direct",
    value: USERS_ROLE_ENUMS.COMPANY_AGENCY_USER,
  },
];
export const LIENS_SOUSCRIPEUR_ASSURE = [
  {
    key: "DEFAULT",
    text: "Choisir...",
    value: "",
  },
  {
    key: "LUI_MEME",
    text: "Lui Même",
    value: "LUI_MEME",
  },
  {
    key: "ASCENDANT",
    text: "Ascendant",
    value: "ASCENDANT",
  },
  {
    key: "BEAUX_PARENT",
    text: "Beaux-Parent",
    value: "BEAUX_PARENT",
  },
  {
    key: "DESCENDANT",
    text: "Descendant",
    value: "DESCENDANT",
  },
  {
    key: "EMPLOYE",
    text: "Employé",
    value: "EMPLOYE",
  },
  {
    key: "MEMBRE",
    text: "Membre",
    value: "MEMBRE",
  },
  {
    key: "PARENT",
    text: "Parent",
    value: "PARENT",
  },
  {
    key: "AUTRE",
    text: "Autre",
    value: "AUTRE",
  },
];

export const TYPES_AVENANTS = {
  RESILLIATION: "RESILLIATION",
  SUSPENSION: "SUSPENSION",
  MODIFICATION_PRISE_EFFET: "MODIFICATION_PRISE_EFFET",
  CHANGEMENT_IMMATRICULATION: "CHANGEMENT_IMMATRICULATION",
  REMISE_EN_VIGUEUR: "REMISE_EN_VIGUEUR",
  INCORPORATION: "INCORPORATION",
  RETRAIT_GARANTIE: "RETRAIT_GARANTIE",
  EMISSION_ATTESTATION: "EMISSION_ATTESTATION",
  DUPLICATA_ATTESTATION: "DUPLICATA_ATTESTATION",
  EXTENSION_GARANTIES: "EXTENSION_GARANTIES",
  CHANGEMENT_NOM: "CHANGEMENT_NOM",
  CHANGEMENT_ASSUREUR: "CHANGEMENT_ASSUREUR",
  AFFAIRE_NOUVELLE: "AFFAIRE_NOUVELLE",
  CHANGEMENT_VEHICULE: "CHANGEMENT_VEHICULE",
};

export const AVENANTS_NECESSITANT_EDITION_ATTESTATION = [
  TYPES_AVENANTS.DUPLICATA_ATTESTATION,
  TYPES_AVENANTS.REMISE_EN_VIGUEUR,
  TYPES_AVENANTS.CHANGEMENT_VEHICULE,
  TYPES_AVENANTS.CHANGEMENT_NOM,
  TYPES_AVENANTS.CHANGEMENT_IMMATRICULATION,
  TYPES_AVENANTS.REMISE_EN_VIGUEUR
];
