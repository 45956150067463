import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import dayjs from "dayjs";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DaylyProductionChart({
  height = 100,
  statisticsData = null,
  start_date = null,
  end_date = null,
  data_type = null,
  legend = false,
}) {
  let labels = [];

  // Validate date inputs
  const startDate = dayjs(start_date);
  const endDate = dayjs(end_date);

  // Generate labels for the specified period
  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    labels.push(currentDate.format("DD MMM"));
    currentDate = currentDate.add(1, "day");
  }

  // Helper function to prepare datasets for the specified period
  const getDatasets = () => {
    // Dynamic custom colors for each company
    const customColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#A6CEE3",
      "#1F78B4",
      "#B2DF8A",
      "#33A02C",
      "#FB9A99",
      "#E31A1C",
      "#FDBF6F",
      "#FF7F00",
      "#CAB2D6",
      "#6A3D9A",
      "#B15928",
      "#8DD3C7",
    ];

    // ATTESTATION MULTI VALUES BY COMPANGIES
    if (data_type === "attestations_values_par_compagnies") {
      // Define the session types as datasets
      const sessionTypes = [
        "Nombre d'attestations consommées",
        "Nombre d'attestations commandées",
        "Nombre d'attestations réceptionnées",
      ];

      // Extract the raw data
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize an object to store aggregated data for companies
      const aggregatedData = {};

      // Loop through the data to aggregate values by company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const companyName = company.denomination;

          // Ensure the company has a placeholder in the aggregated data
          if (!aggregatedData[companyName]) {
            aggregatedData[companyName] = [0, 0, 0]; // [consumed, ordered, received]
          }

          // Aggregate the session values for the company
          aggregatedData[companyName][0] += company.attestationsUsedCount || 0; // Consumed
          aggregatedData[companyName][1] +=
            company.attestationsOrderedCount || 0; // Ordered
          aggregatedData[companyName][2] +=
            company.attestationsOrderedReceivedCount || 0; // Received
        });
      });

      // Extract company names for the labels (X-Axis)
      const companyLabels = Object.keys(aggregatedData);

      // Prepare datasets for each session type
      const datasets = sessionTypes.map((sessionType, index) => ({
        label: sessionType, // Session name (e.g., Consumed, Ordered, Received)
        data: companyLabels.map((company) => aggregatedData[company][index]), // Data for this session type
        backgroundColor: customColors[index % customColors.length], // Unique color for each session
        borderColor: customColors[index % customColors.length], // Matching border color
        borderWidth: 1,
      }));

      // Update labels dynamically based on values
      labels.length = 0; // Clear any existing labels
      labels.push(...companyLabels); // Add the new labels

      // Return the datasets and the company labels
      return datasets;
    } else if (
      data_type === "attestations_values_par_compagnies_old_to_remove"
    ) {
      // Define the labels for the chart (grouped by companies)
      const newLabels = [
        "Nombre d'attestations consommées",
        "Nombre d'attestations commandées",
        "Nombre d'attestations réceptionnées",
      ];

      // Extract the raw data
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize an object to store data grouped by companies
      const aggregatedData = {};

      // Loop through the data to aggregate values for each company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const companyName = company.denomination;

          // Ensure the company has a placeholder in the aggregated data
          if (!aggregatedData[companyName]) {
            aggregatedData[companyName] = [0, 0, 0]; // [consumed, ordered, received]
          }

          // Aggregate the values for the company
          aggregatedData[companyName][0] += company.attestationsUsedCount || 0; // Consumed
          aggregatedData[companyName][1] +=
            company.attestationsOrderedCount || 0; // Ordered
          aggregatedData[companyName][2] +=
            company.attestationsOrderedReceivedCount || 0; // Received
        });
      });

      // Prepare datasets for the chart
      const datasets = Object.keys(aggregatedData).map(
        (companyName, index) => ({
          label: companyName, // Company name
          data: aggregatedData[companyName], // Values for the company
          backgroundColor: customColors[index % customColors.length], // Cycle through custom colors
          borderColor: customColors[index % customColors.length], // Border color matches background
          borderWidth: 1,
        })
      );

      // Update labels dynamically based on values
      labels.length = 0; // Clear any existing labels
      labels.push(...newLabels); // Add the new labels

      // Return the datasets
      return datasets;
    }

    // ATTESTATION MULTI VALUES BY COMPANGIES
    else if (data_type === "attestations_values_par_compagnies_old_to_remove") {
      let newLabels = [
        "Nombre d'attestations consommées",
        "Nombre d'attestations commandées",
        "Nombre d'attestations réceptionnées",
      ];

      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      const aggregatedData = {};

      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          if (!aggregatedData[name]) {
            aggregatedData[name] = [0, 0, 0];
          }
          aggregatedData[name][0] += company.attestationsOrderedCount || 0;
          aggregatedData[name][1] += company.attestationsRequestedCount || 0;
          aggregatedData[name][2] +=
            company.attestationsOrderedReceivedCount || 0;
        });
      });

      let value = Object.keys(aggregatedData).map((companyName, index) => ({
        label: companyName,
        data: aggregatedData[companyName],
        backgroundColor: customColors[index % customColors.length],
        borderColor: customColors[index % customColors.length],
        borderWidth: 1,
      }));
      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...newLabels);
      return value;
    }

    // VALUE BY COMPANIES
    else if (data_type === "chiffresaffaire1_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.primeNette || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Chiffre d'affaires par compagnie (sans garantie facultative)",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }
    //
    else if (data_type === "ordered_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.attestationsOrderedCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }
    //
    else if (data_type === "requested_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.attestationsRequestedCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }
    //
    else if (data_type === "ordered_received_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.attestationsOrderedReceivedCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }
    //
    else if (data_type === "used_attestations_par_compagnies") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      data.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.attestationsUsedCount || 0;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }

    //
    else if (data_type === "chiffresaffaire1_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Chiffre d'affaires par groupes (sans garantie facultative)",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "cars_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVehiculesCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par compagnies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "cars_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVehiculesCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "assured_cars_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVoituresAssuredCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules assurées par compagnies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "assured_cars_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVoituresAssuredCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules assurées par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "echeance_cars_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules à l’échéance par compangies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "echeance_cars_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules à l’échéance par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "chiffresaffaire2_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette + item.primeNetteIac;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Chiffre d'affaires par groups (avec garantie facultative)",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "chiffresaffaire2_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.primeNette + company.primeNetteIac;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Chiffre d'affaires par compagnie (avec garantie facultative)",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "fees_par_compagnies") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.companyStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Chiffre d'affaires par compagnie (avec garantie facultative)",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "fees_par_groups") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.groupStatistics.forEach((company) => {
          const name = company.denomination;
          const amount = company.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Frais de gestion de pool par groupes",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "avenants_counts") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.avenantStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.count;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Nombre d'avenants sur les contrats d'assurance",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "chiffresaffaire1_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par compagnies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "chiffresaffaire2_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette + item.primeNetteIac;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par compagnies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "fees_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Frais de gestion de pool par groupes",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "cars_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVehiculesCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "assured_cars_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVoituresAssuredCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules assurées par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "echeance_cars_par_agences") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.agencyStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules à l’échéance par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }
    // INTERMEDIAIRE
    else if (data_type === "chiffresaffaire1_par_intermediares") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par compagnies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "chiffresaffaire2_par_intermediares") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.primeNette + item.primeNetteIac;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par compagnies",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "fees_par_intermediares") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const companyTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsFeesAmountPaid;

          if (companyTotals[name]) {
            companyTotals[name] += amount; // Sum totals
          } else {
            companyTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const companyLabels = Object.keys(companyTotals); // Company names
      const companyValues = Object.values(companyTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = companyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...companyLabels);

      return [
        {
          label: "Frais de gestion de pool par groupes",
          data: companyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "cars_par_intermediares") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVehiculesCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre total de vehicules par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "assured_cars_par_intermediares") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalVoituresAssuredCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules assurées par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    } else if (data_type === "echeance_cars_par_intermediares") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize objects to sum data per company
      const itemTotals = {};

      // Loop through each day's data to sum up totals per company
      productionData.forEach((dayData) => {
        dayData.intermediaireStatistics.forEach((item) => {
          const name = item.denomination;
          const amount = item.totalSouscriptionsAEcheanceCount || 0;

          if (itemTotals[name]) {
            itemTotals[name] += amount; // Sum totals
          } else {
            itemTotals[name] = amount; // Initialize total
          }
        });
      });

      // Prepare labels and values
      const itemLabels = Object.keys(itemTotals); // Company names
      const itemValues = Object.values(itemTotals); // Total amounts

      // Assign colors based on labels dynamically
      const backgroundColors = itemLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on companies
      labels.length = 0; // Clear previous labels
      labels.push(...itemLabels);

      return [
        {
          label: "Nombre de véhicules à l’échéance par groupes",
          data: itemValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),

          borderWidth: 1,
        },
      ];
    }

    // DAILY CHART
    else if (data_type === "chiffresaffaire1") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      productionData.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.primeNette || 0; // Assuming 'dailyTotal' exists in each day's data

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "Chiffre d'affaires (Sans garantie facultative)",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "chiffresaffaire2") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      productionData.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.primeNette + dayData.primeNetteIac;

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "Chiffre d'affaires (Avec garantie facultative)",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "fees") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      productionData.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.totalSouscriptionsFeesAmountPaid;

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "Frais de gestion de pool",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "cars") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      productionData.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.totalVoituresCount;

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "Nombre total de vehicules",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "assured_cars") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      productionData.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.totalVoituresAssuredCount;

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "Nombre de véhicules assurées",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    } else if (data_type === "echeance_cars") {
      const productionData =
        statisticsData?.production?.data?.productionStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      productionData.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.totalSouscriptionsAEcheanceCount;

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "Nombre de véhicules à l’échéance",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    }

    //
    else if (data_type === "attestations_used_count") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      data.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.attestationsOrderedCount || 0; // Assuming 'dailyTotal' exists in each day's data

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    }

    //
    else if (data_type === "attestations_requested_count") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      data.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.attestationsRequestedCount || 0; // Assuming 'dailyTotal' exists in each day's data

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    }

    //
    else if (data_type === "ordered_received_attestations_count") {
      const data =
        statisticsData?.attestation?.data?.attestationStatistics || [];

      // Initialize object to sum data per day
      const dailyTotals = {};

      // Loop through each day's data to sum up daily totals
      data.forEach((dayData) => {
        const date = dayjs(dayData.day).format("DD MMM");
        const dailyAmount = dayData.attestationsOrderedReceivedCount || 0; // Assuming 'dailyTotal' exists in each day's data

        if (dailyTotals[date]) {
          dailyTotals[date] += dailyAmount; // Sum daily totals
        } else {
          dailyTotals[date] = dailyAmount; // Initialize daily total
        }
      });

      // Prepare labels and values
      const dailyLabels = Object.keys(dailyTotals); // Dates
      const dailyValues = Object.values(dailyTotals); // Daily total amounts

      // Assign colors dynamically based on labels
      const backgroundColors = dailyLabels.map(
        (_, index) => customColors[index % customColors.length] // Cycle through colors
      );

      // Set labels dynamically based on days
      labels.length = 0; // Clear previous labels
      labels.push(...dailyLabels);

      return [
        {
          label: "",
          data: dailyValues,
          backgroundColor: backgroundColors, // Custom colors for bars
          borderColor: backgroundColors.map(
            (color) => color.replace("0.6", "1") // Adjust opacity for borders
          ),
          borderWidth: 1,
        },
      ];
    }

    return [];
  };

  const data = {
    labels, // Company names
    datasets: getDatasets(),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: legend, // Hide the legend
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 50000, // Adjust this based on data range
          precision: 0,
        },
      },
    },
  };

  return (
    <Fragment>
      <Bar data={data} height={height} options={options} />
    </Fragment>
  );
}

export default DaylyProductionChart;
