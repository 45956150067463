import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import EncaissementModal from "../../../components/production/caisse/EncaissementModal";
import * as paymentService from "../../../services/gestion_production/paymentService";
import dayjs from "dayjs";
import numeral from "numeral";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import EncaissementPreviewModal from "../../../components/production/caisse/EncaissementPreviewModal";
import PermissionWrapper from "../../../components/PermissionWrapper";
import {
  getAvenantTypeReadableText,
  getRemiseEnVigueurReadableText,
} from "../../../utils/commons";

function CaissePage() {
  const [payments, setPayments] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openEncaissement, setOpenEncaissement] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [pagination, setPagination] = useState();
  const [openEncaissementPreview, setOpenEncaissementPreview] = useState(false);
  const handleOpenEncaissement = () => setOpenEncaissement(true);

  const handleOpenEncaissementPreview = (paymentUuid) => {
    setSelectedPayment(paymentUuid);
    setOpenEncaissementPreview(true);
  };

  const fetchPayments = async () => {
    try {
      setLoading(true);
      const response = await paymentService.getPayments({
        search,
        page: pagination?.currentPage || 1,
        limit: pagination?.limit || 10,
      });
      setPayments(response.data.payments);
      setPagination(response.data.pagination);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePayment = async (uuid) => {
    try {
      Swal.fire({
        title: "Voulez-vous vraiment supprimer cet encaissement?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await paymentService.deletePayment(uuid);
            fetchPayments();
            toast.success("Encaissement supprimé avec succès!");
          } catch (error) {
            toast.error("Une erreur est survenue pendant la suppression!");
          }
        }
      });
    } catch (error) {
      toast.error("Une erreur est survenue pendant la suppression!");
    }
  };

  const handlePageChange = (e, { activePage }) => {
    setPagination({ ...pagination, currentPage: activePage });
  };

  const handleSuccessEncaissement = () => {
    fetchPayments();
  };

  useEffect(() => {
    fetchPayments();
  }, [search, pagination?.currentPage]);

  return (
    <Page title="Details de la police">
      <GridRow className="CompagnyDetails">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">Caisse</h1>
              <PermissionWrapper
                requiredPermissions={["agency.production.cash-register.add"]}
              >
              <Button
                icon
                labelPosition="left"
                size="small"
                onClick={handleOpenEncaissement}
              >
                <Icon name="plus" />
                Nouveau
              </Button>
              </PermissionWrapper>
            </div>
            <Divider />
          </Grid.Column>
          <Grid.Column width={16}>
            <Segment>
              <div className="flex items-center justify-end">
                <Input
                  icon="search"
                  placeholder="Rechercher..."
                  size="small"
                  className="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Segment basic className="!p-0" loading={loading}>
                <Table striped celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell collapsing>Code</Table.HeaderCell>
                      <Table.HeaderCell>Proposision/Police</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Motif</Table.HeaderCell>
                      <Table.HeaderCell>Montant</Table.HeaderCell>
                      <Table.HeaderCell>Opération</Table.HeaderCell>
                      <Table.HeaderCell>Opérateur</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right" collapsing>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments?.length > 0 ? (
                      payments?.map((payment) => (
                        <Table.Row key={payment.idpayments}>
                          <Table.Cell>{payment.code}</Table.Cell>
                          <Table.Cell>
                            {payment.bills.avenants.souscription
                              .souscription_polices.numero_police ||
                              payment.bills.avenants.souscription
                                .numero_proposition}
                          </Table.Cell>
                          <Table.Cell>
                            {dayjs(payment.created_at).format("DD/MM/YYYY")}
                          </Table.Cell>
                          <Table.Cell>
                            {getAvenantTypeReadableText(
                              payment.bills.avenants.type_avenant
                            )}
                            {`${
                              payment.bills.avenants.type_avenant ===
                              "REMISE_EN_VIGUEUR"
                                ? " - "
                                : ""
                            } ${getRemiseEnVigueurReadableText(
                              payment.bills.avenants
                            )}`}
                          </Table.Cell>
                          <Table.Cell>
                            {payment.bills.souscription_reimbursements
                              ? "- " + numeral(
                                  Number(payment.bills.souscription_reimbursements
                                    ?.amount)
                                ).format("")
                              : numeral(payment.bills.amount).format("")}
                          </Table.Cell>
                          <Table.Cell>
                            {payment.bills.souscription_reimbursements
                              ? "Décaissement"
                              : "Encaissement"}
                          </Table.Cell>
                          <Table.Cell>
                            {payment.users.first_name} {payment.users.last_name}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <Dropdown
                              pointing="right"
                              icon={<Icon name="ellipsis vertical" />}
                            >
                              <Dropdown.Menu>
                              <PermissionWrapper
                                requiredPermissions={["agency.production.cash-register.print"]}
                              >
                                <Dropdown.Item
                                  icon={<Icon name="print" />}
                                  content="Imprimer"
                                  onClick={() =>
                                    handleOpenEncaissementPreview(payment.uuid)
                                  }
                                />
                                </PermissionWrapper>
                                <PermissionWrapper
                                  requiredPermissions={["agency.production.cash-register.delete"]}
                                >
                                <Dropdown.Item
                                  icon={<Icon name="trash" />}
                                  content="Supprimer"
                                  onClick={() =>
                                    handleDeletePayment(payment.uuid)
                                  }
                                />
                                </PermissionWrapper>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={4} textAlign="center">
                          Aucune donnée trouvée
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  {pagination?.totalPages > 1 && (
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell colSpan={7} textAlign="right">
                          <Pagination
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            onPageChange={handlePageChange}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  )}
                </Table>
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </GridRow>
      <EncaissementModal
        open={openEncaissement}
        setOpen={setOpenEncaissement}
        successCallback={handleSuccessEncaissement}
      />
      <EncaissementPreviewModal
        open={openEncaissementPreview}
        setOpen={setOpenEncaissementPreview}
        paymentUuid={selectedPayment}
      />
    </Page>
  );
}

export default CaissePage;
