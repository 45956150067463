import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  FormGroup,
  Header,
  Modal,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as distributionsService from "../../../services/gestion_attestations/intermediaire/distributionsService";
import * as agencyDistributionsService from "../../../services/gestion_attestations/intermediaire/agenciesDistributionsService";
import * as agenciesRequestsService from "../../../services/gestion_attestations/intermediaire/agenciesRequestsServices";
import * as intermediairesService from "../../../services/intermediairesService";
import dayjs from "dayjs";
import { USERS_ROLE_ENUMS } from "../../../constants";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";

const validationSchema = yup.object().shape({
  start_serial: yup
    .number("Veuillez renseigner un numéro de série de début valide")
    .required("Veuillez renseigner le numéro de série de début"),
  end_serial: yup
    .number("Veuillez renseigner un numéro de série de fin valide")
    .min(
      yup.ref("start_serial"),
      "Le numéro de série de fin doit être supérieur au numéro de série de début"
    )
    .required("Veuillez renseigner le numéro de série de fin"),
  company_uuid: yup.string(),
  agency_id: yup.string(),
  //distributions_uuid: yup.string().required("Le magasin est obligatoire"),
});
function IntermediaireDistributionModalForm({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  handleCloseModal = () => {},
}) {
  const [distributionStocks, setDistributionStocks] = useState([]);
  const [intermediaire, setIntermediaire] = useState();
  const [compangies, setCompanies] = useState();
  const [request, setRequest] = useState(null);
  const inputRef = useRef(null);
  const [agences, setAgences] = useState([]);
  const authValue = useRecoilValue(authState);

  const formik = useFormik({
    initialValues: {
      quantity:
        request?.attestations_intermediaires_agencies_distributions?.quantity ||
        0,
      distributions_uuid:
        request?.attestations_intermediaires_agencies_distributions
          ?.attestations_distributions?.uuid || "",
      company_uuid: "",
      agency_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        start_serial: values.start_serial,
        end_serial: values.end_serial,
        agency_id: values.agency_id,
        magasin_stock_uuid: values.distributions_uuid,
        company_uuid: values.company_uuid,
        //distributions_uuid: values.distributions_uuid,
      };

      if (!request?.attestations_intermediaires_agencies_distributions) {
        //data.request_uuid = request?.uuid;
      }
      const requestAction = distributionsService.createDistributionDirect(data);
      requestAction
        .then((response) => {
          toast.success("Distribution directe  d'attestation ajoutée");
          setOpen(false);
          callback();
          formik.resetForm();
          handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.error ||
              "Une erreur est survenue lors de la distribution directe "
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  const fetchStocks = () => {
    distributionsService
      .getStocks({
        company_uuid: values.company_uuid,
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setDistributionStocks(response.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  const getSelectedStock = () => {
    return distributionStocks?.find(
      (m) => m.uuid === values.distributions_uuid
    );
  };

  useEffect(() => {
    fetchStocks();
  }, [values.company_uuid]);

  useEffect(() => {
    formik.resetForm();
  }, [open, selectedItemId]);

  const fetchIntermediaire = async () => {
    try {
      const response = await ([
        USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
        USERS_ROLE_ENUMS.COURTIER_USER,
      ].includes(authValue?.user?.user_role)
        ? intermediairesService.fetchOwner()
        : null);

      if (response?.status === 200) {
        setIntermediaire(response?.data);
      }
    } catch (error) {}
  };

  const fetchCompanies = async () => {
    try {
      const response = await intermediairesService.fetchCompanies();
      if (response?.status === 200) {
        setCompanies(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchIntermediaire();
    fetchCompanies();
    return () => {};
  }, [authValue?.user]);

  return (
    <Modal
      onClose={() => {
        formik.resetForm();
        handleCloseModal();
        setRequest(null);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        Ajouter une nouvelle distribution directe d'attestation
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <Form.Field width={16}>
            <label className="required">Choissisez une compagnie</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner une compagnie"
              name="company_uuid"
              options={[
                { key: "", value: "", text: "Choisir une compagnie" },
              ].concat(
                compangies?.map((item) => ({
                  key: item?.compagnies?.uuid,
                  value: item?.compagnies?.uuid,
                  text: item?.compagnies?.denomination,
                }))
              )}
              onChange={(e, { value }) => {
                formik.setFieldValue("company_uuid", value);
              }}
              onBlur={handleBlur("company_uuid")}
              value={values.company_uuid}
            />
            <small className="field-error">{errors.company_uuid}</small>
          </Form.Field>

          <Form.Field width={16}>
            <label className="required">Choissisez un stocks</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner un stocks"
              name="distributions_uuid"
              options={[
                { key: "", value: "", text: "Choisir un stocks" },
              ].concat(
                distributionStocks?.map((stock) => ({
                  key: stock?.uuid,
                  value: stock?.uuid,
                  text: `[ ${stock?.start_serial} - ${stock?.end_serial} ] ${
                    stock?.usage?.remainingQty
                  } ${
                    stock?.usage?.packaging === "CARNET_CHEQUE"
                      ? "Carnets restants"
                      : "Attestations restantes"
                  }`,
                }))
              )}
              onChange={(e, { value }) => {
                formik.setFieldValue("distributions_uuid", value);
                let stock = distributionStocks.find((s) => s.uuid === value);
                if (stock) {
                  formik.setFieldValue(
                    "start_serial",
                    stock?.usage?.available_start_serial
                  );
                } else {
                  formik.setFieldValue("start_serial", "");
                  formik.setFieldValue("end_serial", "");
                }
              }}
              onBlur={handleBlur("attestations_stocks_uuid")}
              value={
                values.distributions_uuid ||
                request?.attestations_intermediaires_agencies_distributions
                  ?.attestations_magasins_stocks?.attestations_magasins?.uuid
              }
            />
            <small className="field-error">{errors.distributions_uuid}</small>
          </Form.Field>

          <Form.Field width={16}>
            <label className="required">Choissisez une agence</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner une agence"
              name="agency_id"
              options={[
                { key: "", value: "", text: "Choisir une agence" },
              ].concat(
                intermediaire?.intermediaires_agencies?.map((item) => ({
                  key: item?.idintermediaires_agencies,
                  value: item?.idintermediaires_agencies,
                  text: item?.denomination,
                }))
              )}
              onChange={(e, { value }) => {
                formik.setFieldValue("agency_id", value);
              }}
              onBlur={handleBlur("agency_id")}
              value={values.agency_id}
            />
            <small className="field-error">{errors.agency_id}</small>
          </Form.Field>

          {values.distributions_uuid && (
            <>
              <Divider />
              <div>
                <Header as="h6">Dernières distributions</Header>
                <Table basic="very" celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Qté</Table.HeaderCell>
                      <Table.HeaderCell>Numéro de série</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {getSelectedStock()
                      ?.attestations_intermediaires_agencies_distributions
                      ?.length > 0 ? (
                      <Table.Row>
                        <Table.Cell>
                          {`${
                            Number(
                              getSelectedStock()
                                ?.attestations_intermediaires_agencies_distributions[0]
                                ?.end_serial
                            ) -
                            Number(
                              getSelectedStock()
                                ?.attestations_intermediaires_agencies_distributions[0]
                                ?.start_serial
                            ) +
                            1
                          }`}
                        </Table.Cell>
                        <Table.Cell>
                          {`${
                            getSelectedStock()
                              ?.attestations_intermediaires_agencies_distributions[0]
                              ?.start_serial
                          } - ${
                            getSelectedStock()
                              ?.attestations_intermediaires_agencies_distributions[0]
                              ?.end_serial
                          }`}
                        </Table.Cell>
                        <Table.Cell>
                          {dayjs(
                            getSelectedStock()
                              ?.attestations_intermediaires_agencies_distributions[0]
                              ?.created_at
                          ).format("DD/MM/YYYY HH:mm")}
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={3}>
                          <div className="text-center">
                            <p>Aucune distribution pour le moment!</p>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
              <Divider />
            </>
          )}

          <Form.Field width={8}>
            <label>Quantité</label>
            <input
              ref={inputRef}
              type="number"
              onChange={(e) => {
                const quantity = e.target.value;
                if (!isNaN(quantity) && quantity > 0) {
                  const startSerial = formik.values.start_serial;
                  formik.setFieldValue(
                    "end_serial",
                    (Number(startSerial) + Number(quantity) - 1)
                      .toString()
                      .padStart(formik.values.start_serial.length, "0")
                  );
                }
              }}
            />
          </Form.Field>

          <FormGroup widths="equal">
            <Form.Field>
              <label>Numéro de série de début</label>
              <Form.Input
                name="start_serial"
                value={values.start_serial}
                onChange={(e, { value }) => {
                  formik.setFieldValue("start_serial", value);
                  formik.setFieldValue(
                    "end_serial",
                    (Number(value) + Number(formik.values.quantity) - 1)
                      .toString()
                      .padStart(formik.values.start_serial.length, "0")
                  );
                  inputRef.current.value = "";
                }}
                error={errors.start_serial}
                min={
                  distributionStocks?.find(
                    (m) => m.uuid === values.distributions_uuid
                  )?.start_serial || 0
                }
                max={
                  distributionStocks?.find(
                    (m) => m.uuid === values.distributions_uuid
                  )?.end_serial || 0
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Numéro de série de fin</label>
              <Form.Input
                name="end_serial"
                value={values.end_serial}
                onChange={(e, { value }) => {
                  formik.setFieldValue("end_serial", value);
                  inputRef.current.value = "";
                }}
                error={errors.end_serial}
                min={values.start_serial}
                max={
                  distributionStocks?.find(
                    (m) => m.uuid === values.distributions_uuid
                  )?.end_serial || 0
                }
              />
            </Form.Field>
          </FormGroup>
          {!isNaN(Number(values.start_serial)) &&
          !isNaN(Number(values.end_serial)) &&
          Number(values.start_serial) <= Number(values.end_serial) ? (
            <div className="text-right text-gray-500 font-bold">
              {`Quantité d'attestations: ${
                Number(values.end_serial) - Number(values.start_serial) + 1
              }`}
            </div>
          ) : null}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediaireDistributionModalForm;
