import { api } from "../../../axios/api";

export const getStocks = async (params) => {
  return api.get("gestion-attestations/intermediaires/distributions/stocks", {
    params: {
      ...params,
    },
  });
};

export const createDistributionDirect = async (data) => {
  return api.post(
    "gestion-attestations/intermediaires/distributions/direct",
    data
  );
};
