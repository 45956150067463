import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import attesttionTemplate from "../../assets/images/templates/attestation_assurance.png";
import dayjs from "dayjs";

function AttestionModal({ open = false, onClose = () => {}, data }) {
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!open) return; // Ne rien faire si la modale n'est pas ouverte

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();

    // Réinitialisation du canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    image.src = attesttionTemplate;
    image.onload = () => {
      const maxWidth = 800;
      let width = image.width;
      let height = image.height;

      if (width > maxWidth) {
        const scale = maxWidth / width;
        width = maxWidth;
        height = height * scale;
      }

      setImgSize({ width, height });
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, width, height);
      ctx.font = "14px Times New Roman";
      ctx.fillStyle = "black";

      // Part one
      ctx.fillText(
        data.souscription_polices?.numero_police || "",
        width * 0.13,
        height * 0.39
      );
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_effet).format("DD/MM/YYYY") ||
          "",
        width * 0.1 - 9,
        height * 0.45
      );
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_echeance).format(
          "DD/MM/YYYY"
        ) || "",
        width * 0.19,
        height * 0.45
      );
      ctx.fillText(
        data.souscription_identite_vehicule?.marque_vehicule || "",
        width * 0.17,
        height * 0.52
      );
      ctx.fillText(
        data.souscription_identite_vehicule?.immatriculation_vehicule || "",
        width * 0.1 - 20,
        height * 0.65
      );
      ctx.fillText(4, width * 0.18, height * 0.75);

      // Part two
      ctx.fillText(
        data.souscripteur_assure?.assures?.nom_assure || "",
        width * 0.35,
        height * 0.21
      );
      ctx.fillText(
        data.souscripteur_assure?.assures?.jobs?.libelle || "",
        width * 0.47,
        height * 0.27
      );
      ctx.fillText(
        data.souscription_polices?.numero_police || "",
        width * 0.44,
        height * 0.33
      );
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_effet).format("DD/MM/YYYY") ||
          "",
        width * 0.39,
        height * 0.41
      );
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_echeance).format(
          "DD/MM/YYYY"
        ) || "",
        width * 0.56,
        height * 0.41
      );
      ctx.fillText(
        data.souscription_identite_vehicule?.marque_vehicule || "",
        width * 0.46,
        height * 0.5
      );
      ctx.fillText(
        data.souscription_identite_vehicule?.immatriculation_vehicule || "",
        width * 0.35,
        height * 0.64
      );
      ctx.fillText(4, width * 0.47, height * 0.75);

      // Part three
      ctx.fillText(
        data.souscription_identite_vehicule?.type_vehicule || "",
        width * 0.39,
        height * 0.91
      );
      ctx.fillText(
        data.souscription_identite_vehicule?.marque_vehicule || "",
        width * 0.58,
        height * 0.91
      );
      ctx.fillText(
        data.souscription_identite_vehicule?.immatriculation_vehicule || "",
        width * 0.49,
        height * 0.97
      );
      ctx.save();
      ctx.translate(width * 0.283, height * 0.62);
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(data.souscription_polices?.numero_attestation || "", 0, 0);
      ctx.restore();

      ctx.save();
      ctx.translate(width * 0.69, height * 0.62);
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(data.souscription_polices?.numero_attestation || "", 0, 0);
      ctx.restore();
    };

    return () => {
      // Nettoyage lorsque la modale est fermée
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [data, open]);

  return (
    <Modal open={open} onClose={onClose} closeIcon size="">
      <Modal.Header>Prévisualisation de l'attestation</Modal.Header>
      <Modal.Content
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#2b2a33",
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            maxWidth: "100%",
            width: imgSize.width,
            height: imgSize.height,
            border: "1px solid #000",
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button size="tiny" onClick={onClose}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AttestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default AttestionModal;
