import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ConditionsParticulieresGaranties from "../previewSections/ConditionsParticulieresGaranties";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";
import { findOne } from "../../../services/gestion_production/policesService";
import PageContentWrapper from "../previewSections/partials/PageContentWrapper";
import numeral from "numeral";

const priceArray = [
  {
    label: "Coût de l'avenant",
    value: 5000,
  },
  {
    label: "Taxe",
    value: 750,
  }
];

function SuspensionPreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PageContentWrapper souscription={souscription}>
            <PreviewTemplateLayout souscription={souscriptionWithAvenant} />
            <PreviewContentWrapper souscription={souscriptionWithAvenant}>
              <View style={styles.avenantHeaderSection}>
                <Text style={styles.avenantHeaderTitle}>
                  {avenant?.bills?.payments
                    ? "Avenant de suspension de contrat"
                  : "Projet d'avenant de suspension de contrat"}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    width: "50%",
                    padding: 5,
                    border: 1,
                    borderColor: "#CCC",
                    borderStyle: "solid",
                  }}
                >
                  <Text>Références du Souscripteur</Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    padding: 5,
                    border: 1,
                    borderColor: "#CCC",
                    borderStyle: "solid",
                  }}
                >
                  <Text>Références Suspension de contrat</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  gap: 5,
                  marginBottom: 5,
                  fontSize: 9,
                }}
              >
                <View
                  style={{
                    width: "50%",
                    padding: 10,
                    border: 1,
                    borderColor: "#CCC",
                    borderStyle: "solid",
                  }}
                >
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>NUI:</Text>
                    <Text> {souscriptionWithAvenant?.souscripteurs?.nui}</Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Nom:</Text>
                    <Text>
                      {`Souscripteur: ${
                        souscriptionWithAvenant?.souscripteurs
                          ?.groupe_souscripteur === "ENTREPRISE"
                          ? `${souscriptionWithAvenant?.souscripteurs?.denomination}`
                        : `${souscriptionWithAvenant?.souscripteurs?.first_name} ${souscriptionWithAvenant?.souscripteurs?.last_name}`
                      }`}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Adresse:</Text>
                    <Text>
                      {souscriptionWithAvenant?.souscripteurs?.address || "NA"}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Téléphone:</Text>
                    <Text>
                      {souscriptionWithAvenant?.souscripteurs?.phone || "NA"}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Profession</Text>
                    <Text>:</Text>
                    <Text>
                      {souscriptionWithAvenant?.souscripteurs?.jobs?.libelle ||
                        "NA"}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Réseau:</Text>
                    <Text></Text>
                  </View>
                  {souscriptionWithAvenant?.souscription_origine
                    ?.intermediaires && (
                    <View
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    >
                      <Text>Intermédiaire:</Text>
                      <Text>
                        {
                          souscriptionWithAvenant?.souscription_origine
                            ?.intermediaires?.denomination
                        }
                      </Text>
                    </View>
                  )}
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Email:</Text>
                    <Text>
                      {souscriptionWithAvenant?.souscripteurs?.email || "NA"}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Bureau de souscription:</Text>
                    <Text>
                      {souscriptionWithAvenant?.souscription_origine
                        ?.intermediaires_agencies?.denomination ||
                        souscriptionWithAvenant?.souscription_origine
                          ?.compagnies_agencies?.denomination}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: "50%",
                    padding: 10,
                    border: 1,
                    borderColor: "#CCC",
                    borderStyle: "solid",
                  }}
                >
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>N° police:</Text>
                    <Text>
                      {
                        souscriptionWithAvenant?.souscription_polices
                          ?.numero_police
                      }
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Assuré(e):</Text>
                    <Text>
                      {
                        souscriptionWithAvenant?.souscripteur_assure?.assures
                          ?.nom_assure
                      }
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Adresse:</Text>
                    <Text>
                      {souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.address_assure || "NA"}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Date d'effet du contrat:</Text>
                    <Text>
                      {dayjs(
                        souscriptionWithAvenant?.conditions_particulieres
                          ?.date_effet
                      ).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Date d'échéance du contrat:</Text>
                    <Text>
                      {dayjs(
                        souscriptionWithAvenant?.conditions_particulieres
                          ?.date_echeance
                      ).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Date d'effet de la suspension:</Text>
                    <Text>
                      {dayjs(avenant?.meta_data?.date_avenant)
                        .add(1, "d")
                        .format("DD/MM/YYYY")}
                    </Text>
                  </View>

                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Branche:</Text>
                    <Text>Automobile</Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Catégorie:</Text>
                    <Text>04</Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Mouvement:</Text>
                    <Text>Suspension de contrat</Text>
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Text>Type:</Text>
                    <Text>Mono</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  marginTop: 10,
                  fontSize: 9,
                  textAlign: "justify",
                  marginBottom: 10,
                }}
              >
                <Text>
                  A la demande du Souscripteur et d'un commun accord entre les
                  deux parties, les garanties du présent contrat dans le tableau
                  ci-dessus sont suspendues dans tous leurs effets à compter du{" "}
                  {dayjs(avenant?.meta_data?.date_avenant)
                    .add(1, "d")
                    .format("DD/MM/YYYY")}
                  .
                </Text>
                <Text>
                  Les effets de la Police en ce qui concerne les risques
                  suspendus, reprendront avant l'expiration d'un délai de 09
                  mois sur déclaration du Souscripteur constaté par Avenant.
                </Text>
                <Text>
                  Le souscripteur bénéficiera alors, à condition que la
                  suspension ne résulte pas d'un sinistre couvert et qu'elle
                  soit supérieure à 4 semaines consécutives, de l'une ou de
                  l'autre des dispositions suivantes:
                </Text>
                <View style={{ marginLeft: 10, marginTop: 5 }}>
                  <Text>
                    - Ristourne de prime égale à 3/4 du prorata de prime
                    afférent à la période de suspension, à valoir sur la
                    prochaine année d'assurance.
                  </Text>
                  <Text>
                    - Report d'échéance égal au 3/4 de la période de suspension.
                  </Text>
                </View>
                <View style={{ marginTop: 5 }}>
                  <Text>
                    A défaut de remise en vigueur dans le délai 09 mois prévu
                    ci-dessus, le Contrat sera résillié de plein droit, les
                    primes échus restants acquises à la compagnie.
                  </Text>
                  <Text>
                    Il n'est autrement dérogé aux autres termes du présent
                    Contrat.
                  </Text>
                </View>
              </View>
              <ConditionsParticulieresGaranties
                souscription={souscription}
                styles={styles}
              />
              <View style={{ marginTop: 5 }}>
                <Text>
                  De ce qui précède, le souscripteur s'engage à payer au
                  comptant à la signature du présent avenant , la somme de{" "}
                  {numeral(
                    priceArray.reduce((acc, price) => acc + price.value, 0)
                  ).format()}{" "}
                  FCFA décomposée comme suit:
                </Text>
              </View>
              <View
                  style={{
                    marginTop: 10,
                    borderTopWidth: 1,
                    borderColor: "#000",
                    paddingTop: 3,
                  }}
                >
                  {priceArray.map((price, index) => (
                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: 2,
                      }}
                      key={index}
                    >
                      <Text style={{ width: "70%" }}>{price.label}</Text>
                      <Text style={{ width: "30%", textAlign: "right" }}>
                        {numeral(price.value).format()} FCFA
                      </Text>
                    </View>
                  ))}
  
                  <View
                    style={{
                      flexDirection: "row",
                      borderTop: 1,
                      marginTop: 5,
                      paddingTop: 5,
                      fontSize: 12,
                      borderBottomWidth: 1,
                      borderBottomColor: "#000",
                      paddingBottom: 5,
                    }}
                  >
                    <Text style={{ width: "70%", fontWeight: "bold" }}>
                      Total à payer
                    </Text>
                    <Text
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      {numeral(
                        priceArray.reduce((acc, price) => acc + price.value, 0)
                      ).format()}{" "}
                      FCFA
                    </Text>
                  </View>
              </View>
              <View style={{ marginTop: 15, fontSize: 9, textAlign: "right" }}>
                <Text>
                  Fait à _____________________________________ le{" "}
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
                <View
                  style={{
                    marginTop: 5,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  <Text>Le Souscripteur</Text>
                  <Text>Pour la compagnie</Text>
                </View>
              </View>
            </PreviewContentWrapper>
          </PageContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default SuspensionPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 30,
    fontSize: 8,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 5,
    marginTop: 5,
  },
  avenantHeaderTitle: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
});
