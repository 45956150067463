import React, { useEffect, useCallback, useState } from "react";
import { Button, Form, Input, Modal, Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as carBrandsService from "../../services/carBrandsService";
import * as carTypesService from "../../services/carTypesService";

const validationSchema = yup.object().shape({
  libelle: yup
    .string()
    .required("Le libellé est requis")
    .min(2, "Le libellé doit contenir au moins 2 caractères")
    .max(100, "Le libellé ne doit pas dépasser 100 caractères"),
  idcarbrand: yup.string(),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function CarBrandFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  handleClose = () => {},
}) {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  const [carBrands, setCarBrands] = useState([]);
  const fetchType = useCallback(async () => {
    if (!selectedItemId) {
      return;
    }

    try {
      const response = await carTypesService.fetchOne(selectedItemId);
      const brandData = response.data.car_brands || response.data;

      if (brandData.libelle) {
        formik.setValues({
          libelle: brandData.libelle,
          idcarbrand: brandData?.carbrand?.idcarbrands,
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération du type de marque: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedItemId]);

  const formik = useFormik({
    initialValues: {
      libelle: "",
      idcarbrand: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedItemId;

        const serviceCall = isEditMode
          ? carTypesService.update(selectedItemId, values)
          : carTypesService.create(values);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          const successMessage = isEditMode
            ? "Type modifié avec succès"
            : "Type ajouté avec succès";
          toast.success(successMessage, TOAST_CONFIG);

          await callback();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchCarBrands = async () => {
    try {
      const response = await carBrandsService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: undefined,
      });

      if (response.status === 200) {
        const {
          carbrands,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;

        setCarBrands(carbrands);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des marques de voiture");
    } finally {
    }
  };

  useEffect(() => {
    fetchCarBrands();
  }, []);

  useEffect(() => {
    if (selectedItemId) {
      fetchType();
    } else {
      formik.resetForm();
    }
  }, [selectedItemId, fetchType]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>
        {selectedItemId
          ? "Modifier un type de voiture"
          : "Ajouter un type de voiture"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.libelle}>
            <label>Libellé</label>
            <Input
              placeholder="Entrez le libellé"
              value={formik.values.libelle}
              onChange={(e, { value }) => {
                formik.setFieldValue("libelle", value);
              }}
              onBlur={formik.handleBlur("libelle")}
              error={formik.touched.libelle && formik.errors.libelle}
            />
            {formik.touched.libelle && formik.errors.libelle && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.libelle}
              </small>
            )}
          </Form.Field>
          <Form.Field width={16}>
            <label className="required">Choissisez une marque</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner une marque"
              name="idcarbrand"
              options={[
                { key: "", value: "", text: "Choisir une marque" },
              ].concat(
                carBrands?.map((item) => ({
                  key: item?.idcarbrands,
                  value: item?.idcarbrands,
                  text: item?.libelle,
                }))
              )}
              onChange={(e, { value }) => {
                formik.setFieldValue("idcarbrand", value);
              }}
              onBlur={formik.handleBlur("idcarbrand")}
              value={formik.values.idcarbrand}
            />
            <small className="field-error">{formik.errors.idcarbrand}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          type="button"
          content={selectedItemId ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            formik.handleSubmit();
          }}
          positive
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CarBrandFormModal;
