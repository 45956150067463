import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";
import { findOne } from "../../../services/gestion_production/policesService";
import numeral from "numeral";
import {
  calculateCoefficient,
  calculateSouscriptionDuration,
} from "../../../utils/souscription_helper";
import PageContentWrapper from "../previewSections/partials/PageContentWrapper";

function ChangementVehiculePreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PageContentWrapper souscription={souscription}>
            <PreviewTemplateLayout souscription={souscriptionWithAvenant} />
            <PreviewContentWrapper souscription={souscriptionWithAvenant}>
              <View style={styles.avenantHeaderSection}>
                <Text style={styles.avenantHeaderTitle}>
                  {avenant?.bills?.payments
                    ? "Avenant de changement de véhicule"
                   : "Projet d'avenant de changement de véhicule"}
                </Text>
              </View>

              <View>
                <View
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    fontWeight: "bold",
                    marginTop: 5,
                  }}
                >
                  <Text>
                    (Concerne la police Automobile n°{" "}
                    {
                      souscriptionWithAvenant?.souscription_polices
                        ?.numero_police
                    }
                    )
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 10,
                    marginTop: 5,
                    borderColor: "#CCC",
                    borderWidth: 1,
                    padding: 10,
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <View
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <Text>Assuré:</Text>
                      <Text>
                        {
                          souscriptionWithAvenant?.souscripteur_assure?.assures
                            ?.nom_assure
                        }
                      </Text>
                    </View>
                    <View
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <Text>Adresse:</Text>
                      <Text>
                        {
                          souscriptionWithAvenant?.souscripteur_assure?.assures
                            ?.address_assure
                        }
                      </Text>
                    </View>
                    <View
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <Text>Téléphone:</Text>
                      <Text>
                        {
                          souscriptionWithAvenant?.souscripteur_assure?.assures
                            ?.phone_assure
                        }
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: "50%" }}>
                    <View
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <Text>Date d'effet du contrat:</Text>
                      <Text>
                        {dayjs(
                          souscriptionWithAvenant?.conditions_particulieres
                            ?.date_effet
                        ).format("DD/MM/YYYY")}
                      </Text>
                    </View>

                    <View
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <Text>Date d'échéance du contrat:</Text>
                      <Text>
                        {dayjs(
                          souscriptionWithAvenant?.conditions_particulieres
                            ?.date_echeance
                        ).format("DD/MM/YYYY")}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 5 }}>
                  <View>
                    <Text>
                      A la demande de l'assuré et d'un commun accord entre les
                      parties, il est convenu qu'à compter du{" "}
                      {dayjs(avenant?.date_avenant)
                        .add(1, "day")
                        .format("DD MMMM YYYY")}
                      , le véhicule{" "}
                      {
                        souscriptionWithAvenant?.souscription_identite_vehicule
                          ?.marque_vehicule
                      }{" "}
                      {
                        souscriptionWithAvenant?.souscription_identite_vehicule
                          ?.type_vehicule
                      }{" "}
                      {
                        souscriptionWithAvenant?.souscription_identite_vehicule
                          ?.genre_vehicule
                      }
                      {" , "}
                      immatriculé{" "}
                      {
                        souscriptionWithAvenant?.souscription_identite_vehicule
                          ?.immatriculation_vehicule
                      }{" "}
                      cat 04,{" "}
                      {
                        souscriptionWithAvenant?.souscription_identite_vehicule
                          ?.nombre_places
                      }{" "}
                      places, objet du présent contrat est retiré des garanties
                      en remplacement de celui dont les caractéristiques sont
                      rappelées ci-dessous:
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        textDecoration: "underline",
                      }}
                    >
                      Véhicule:
                    </Text>
                    <Text style={{ paddingLeft: 10 }}>
                      {avenant?.meta_data?.identite_vehicule?.marque_vehicule}{" "}
                      {avenant?.meta_data?.identite_vehicule?.type_vehicule}{" "}
                      {avenant?.meta_data?.identite_vehicule?.genre_vehicule} ,
                      immatriculé{" "}
                      {
                        avenant?.meta_data?.identite_vehicule
                          ?.immatriculation_vehicule
                      }
                      , cat 04,{" "}
                      {avenant?.meta_data?.identite_vehicule?.nombre_places}{" "}
                      places, puissance fiscale{" "}
                      {avenant?.meta_data?.identite_vehicule?.puissance_fiscale}
                      ,
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        textDecoration: "underline",
                      }}
                    >
                      Garanties:
                    </Text>
                    <View style={{ marginTop: 10 }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "0.5px solid #CCC",
                          paddingBottom: 2,
                          paddingTop: 2,
                        }}
                      >
                        <Text>Responsabilité Civile</Text>
                        <Text>
                          {numeral(avenant?.meta_data?.prime_nette).format("")}
                        </Text>
                      </View>
                      {avenant?.meta_data?.garanties.map(
                        (garantie, index) =>
                          garantie.value > 0 && (
                            <>
                              <View
                                key={`${index}`}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  borderBottom: "0.5px solid #CCC",
                                  paddingBottom: 2,
                                  paddingTop: 2,
                                }}
                              >
                                <Text>
                                  {
                                    garantie
                                      .pool_directives_tarifaires_has_listes_garanties
                                      .liste_garanties.name
                                  }
                                </Text>
                                <Text>
                                  {numeral(garantie.value).format("")}
                                </Text>
                              </View>
                            </>
                          )
                      )}
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "0.5px solid #CCC",
                          paddingBottom: 2,
                          paddingTop: 2,
                          marginTop: 10,
                        }}
                      >
                        <Text>Nouvelle prime nette annuelle</Text>
                        <Text>
                          {numeral(
                            avenant?.meta_data?.prime_nette_totale -
                              souscription?.prime_nette_iac
                          ).format("")}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "0.5px solid #CCC",
                          paddingBottom: 2,
                          paddingTop: 2,
                        }}
                      >
                        <Text>Ancienne prime nette annuelle</Text>
                        <Text>
                          {numeral(
                            souscriptionWithAvenant?.prime_nette_totale -
                              souscription?.prime_nette_iac
                          ).format("")}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "0.5px solid #CCC",
                          paddingBottom: 2,
                          paddingTop: 2,
                        }}
                      >
                        <Text>Différence nette</Text>
                        <Text>
                          {numeral(
                            Math.abs(
                              Number(avenant?.meta_data?.prime_nette_totale) -
                                Number(
                                  souscriptionWithAvenant?.prime_nette_totale
                                )
                            )
                          ).format("")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              {Number(avenant?.meta_data?.prime_nette_totale) ===
              Number(souscriptionWithAvenant?.prime_nette_totale) ? (
                <View style={{ marginTop: 10, marginBottom: 10 }}>
                  <Text>
                    En conséquence, les véhicules ayant les mêmes
                    caractéristiques identiques, il n'y a pas de modification de
                    prime.
                  </Text>
                </View>
              ): null}

              {Number(avenant?.meta_data?.ristourne) > 0 ? (
                <View style={{ marginTop: 10 }}>
                  <Text>
                    la prime du véhicule de remplacement étant inférieure à
                    celle du premier véhicule, une ristourne de prime est faite
                    à l’assuré calculée au prorata de la période restante à
                    courir En conséquence, l'assuré bénéficie d'une ristourne de
                    prime de{" "}
                    {numeral(Number(avenant?.bills?.amount)).format("")}{" "}
                    FCFA.
                  </Text>
                </View>
              ): null}

              {Number(avenant?.meta_data?.prime_nette_a_payer) > 0 ? (
                <View style={{ marginTop: 10 }}>
                  <Text>
                    En conséquence, l'assuré paie comptant, au titre du présent
                    avenant, la somme de{" "}
                    {numeral(
                      avenant?.meta_data?.prime_nette_a_payer +
                        avenant?.meta_data?.frais
                    ).format("")}{" "}
                    FCFA, ainsi décomptée pour la période allant du{" "}
                    {dayjs(avenant?.date_avenant)
                      .add(1, "day")
                      .format("DD/MM/YYYY")}{" "}
                    au{" "}
                    {dayjs(
                      souscriptionWithAvenant?.conditions_particulieres
                        ?.date_echeance
                    ).format("DD/MM/YYYY")}{" "}
                    , soit{" "}
                    {dayjs(
                      souscriptionWithAvenant?.conditions_particulieres
                        ?.date_echeance
                    ).diff(dayjs(avenant?.date_avenant), "days")}{" "}
                    jours (coefficient:{" "}
                    {calculateCoefficient(
                      avenant?.date_avenant,
                      souscriptionWithAvenant?.conditions_particulieres
                        ?.date_echeance,
                      souscriptionWithAvenant
                    )}
                    ) .
                  </Text>
                  <View>
                    <View style={{ marginTop: 10 }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "0.5px solid #CCC",
                          paddingBottom: 2,
                          paddingTop: 2,
                        }}
                      >
                        <Text>
                          {numeral(
                            Math.abs(
                              Number(avenant?.meta_data?.prime_nette_totale) -
                                Number(
                                  souscriptionWithAvenant?.prime_nette_totale
                                )
                            )
                          ).format("")}{" "}
                          X{" "}
                          {calculateCoefficient(
                            avenant?.date_avenant,
                            souscriptionWithAvenant?.conditions_particulieres
                              ?.date_echeance,
                            souscriptionWithAvenant
                          )}{" "}
                          FCFA
                        </Text>
                        <Text>
                          {numeral(
                            Math.abs(
                              Number(avenant?.meta_data?.prime_nette_totale) -
                                Number(
                                  souscriptionWithAvenant?.prime_nette_totale
                                )
                            ) *
                              calculateCoefficient(
                                avenant?.date_avenant,
                                souscriptionWithAvenant
                                  ?.conditions_particulieres?.date_echeance,
                                souscriptionWithAvenant
                              )
                          ).format("")}{" "}
                          FCFA
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ): null}

              <View>
                <View>
                  {Number(avenant?.meta_data?.ristourne) > 0 ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 10,
                        fontWeight: "bold",
                        borderBottom: "0.5px solid #CCC",
                        paddingBottom: 2,
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        Prime nette
                      </Text>
                      <Text style={{ fontWeight: "bold" }}>
                        - {numeral(
                          Number(avenant?.meta_data?.ristourne)
                        ).format()}{" "}
                        FCFA
                      </Text>
                    </View>
                  ): null}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 2,
                      fontWeight: "bold",
                      borderBottom: "0.5px solid #CCC",
                      paddingBottom: 2,
                    }}
                  >
                    <Text style={{ fontWeight: "bold" }}>
                      Coût de l'avenant
                    </Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {numeral(5000).format("")} FCFA
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "0.5px solid #CCC",
                      paddingBottom: 2,
                      paddingTop: 2,
                    }}
                  >
                    <Text>Taxe</Text>
                    <Text>
                      - {numeral(Number(avenant?.meta_data?.taxes_primes)).format("")}{" "}
                      FCFA
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 2,
                      fontWeight: "bold",
                      borderBottom: "0.5px solid #CCC",
                      paddingBottom: 2,
                    }}
                  >
                    <Text style={{ fontWeight: "bold" }}>Carte Rose</Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {numeral(avenant?.meta_data?.carte_rose).format("")} FCFA
                    </Text>
                  </View>
                  {Number(avenant?.meta_data?.prime_nette_a_payer) > 0 ||
                  (Number(avenant?.meta_data?.ristourne === 0) &&
                    Number(avenant?.meta_data?.prime_nette_a_payer) === 0) ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 2,
                        fontWeight: "bold",
                        borderBottom: "0.5px solid #CCC",
                        paddingBottom: 2,
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>Totale à payer</Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {numeral(
                          avenant?.bills?.amount
                        ).format("")}{" "}
                        FCFA
                      </Text>
                    </View>
                  ): null}
                </View>
              </View>
              {Number(avenant?.meta_data?.ristourne) > 0 ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 10,
                    fontWeight: "bold",
                    borderBottom: "0.5px solid #CCC",
                    paddingBottom: 2,
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {Number(avenant?.meta_data?.prime_nette_a_payer) > 0
                      ? "Prime nette"
                     : "Prime total à ristourner"}
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {Number(avenant?.bills?.amount) <
                    Number(avenant?.meta_data?.ristourne)
                      ? "- " + numeral(Number(avenant?.bills?.amount)).format("")
                     : "- " + numeral(Number(avenant?.bills?.amount)).format("")}{" "}
                    FCFA
                  </Text>
                </View>
              ): null}

              <View style={{ marginTop: 15 }}>
                <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                  Il n’est rien changé d’autres aux conditions tant générales
                  que particulières de la police.
                </Text>
              </View>

              <View style={{ marginTop: 25, fontSize: 10, textAlign: "right" }}>
                <Text>
                  Fait à _____________________________________ le{" "}
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  <Text>Le Souscripteur</Text>
                  <Text>Pour la compagnie</Text>
                </View>
              </View>
            </PreviewContentWrapper>
          </PageContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ChangementVehiculePreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 30,
    fontSize: 9,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 5,
  },
  avenantHeaderTitle: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
});
