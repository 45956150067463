import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { useParams, Link } from "react-router-dom";
import Page from "../../components/Page";
import * as intermediairesAgenciesService from "../../services/intermediairesAgenciesService";
import IntermediaireAgencyUserFormModal from "../../components/modalForms/IntermediaireAgencyUserFormModal";
import PermissionWrapper from "../../components/PermissionWrapper";

function IntermediaireAgencyDetails() {
  const [agency, setAgency] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);

  const params = useParams();

  const fetchAgency = async () => {
    try {
      const response = await intermediairesAgenciesService.fetchOne(params?.id || "");

      if (response?.status === 200) {
        setAgency(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchAgency();
    return () => {};
  }, [params?.id]);

  return (
    <Page title="Détail de l'agence">
      <GridRow className="IntermediaireAgencyDetails">
        <Header>Détails de l'agence</Header>
        <GridRow as={Segment}>
          <Label ribbon>{agency?.denomination}</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>{agency?.denomination}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {agency?.phone_1 || "-"}/{agency?.phone_2 || "-"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Adresse</List.Header>
                    <List.Description>{agency?.adress}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des utilisateurs</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
            <PermissionWrapper
              requiredPermissions={[
                "company.partners.general-agent.agences.users.list",
                "agent_general.agency.users.list",
                "broker.agency.users.list",
              ]}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                "company.partners.general-agent.agences.users.add",
                "agent_general.agency.agences.users.add",
                "broker.agency.users.add",
              ]}
            >
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenUserModal(true)}
              />
            </PermissionWrapper>
            </Grid.Column>
          </Grid>
          <PermissionWrapper
            requiredPermissions={[
              "company.partners.general-agent.agences.users.list",
              "agent_general.agency.agences.users.list",
              "broker.agency.users.view",
            ]}
          >
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {agency?.users_intermediaires_agencies?.map((user_agency, index) => {
                return (
                  <Table.Row key= {`${user_agency.idintermediaires_agencies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{user_agency?.users?.last_name}</Table.Cell>
                    <Table.Cell>{user_agency?.users?.first_name}</Table.Cell>
                    <Table.Cell>{user_agency?.users?.email}</Table.Cell>
                    <Table.Cell>{user_agency?.users?.phone}</Table.Cell>
                    <Table.Cell>{`${
                      user_agency?.is_admin === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell>{`${
                      user_agency?.users?.active === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                        <PermissionWrapper
                          requiredPermissions={[
                            "company.partners.general-agent.agences.users.details",
                            "agent_general.agency.agences.users.details",
                            "broker.agency.users.details",
                          ]}
                        >
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            as={Link}
                            to={`/intermediaire/agences/${params.id}/users/${user_agency.idusers}`}
                          />
                          </PermissionWrapper>
                          <PermissionWrapper
                            requiredPermissions={[
                              "company.partners.general-agent.agences.users.edit",
                              "agent_general.agency.agences.users.edit",
                              "broker.agency.users.edit",
                            ]}
                          >
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          </PermissionWrapper>
                          <PermissionWrapper
                            requiredPermissions={[
                              "company.partners.general-agent.agences.users.delete",
                              "agent_general.agency.agences.users.delete",
                              "broker.agency.users.delete",
                            ]}
                          >
                          <Dropdown.Item
                            icon={<Icon name="trash" />}
                            content="Supprimer"
                          />
                          </PermissionWrapper>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          </PermissionWrapper>
        </GridRow>
      </GridRow>
      <IntermediaireAgencyUserFormModal
        setOpen={setOpenUserModal}
        open={openUserModal}
        agencyId={params.id}
        callback={fetchAgency}
      />
    </Page>
  );
}

export default IntermediaireAgencyDetails;
