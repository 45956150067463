import React from "react";
import { Routes, Route } from "react-router-dom";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import CarBrandsPage from "../pages/dictionnaire/CarBrandsPage";
import CarTypesPage from "../pages/dictionnaire/CarTypesPage";
import CategoriesContrats from "../pages/dictionnaire/CategoriesContrats";
import CivilitiesPage from "../pages/dictionnaire/CivilitiesPage";
import CountriesPage from "../pages/dictionnaire/CountriesPage";
import JobsPage from "../pages/dictionnaire/JobsPage";
import PiecesOfficiellesPage from "../pages/dictionnaire/PiecesOfficiellesPage";
import SourcesEnergies from "../pages/dictionnaire/SourcesEnergies";
import ZonesCirculation from "../pages/dictionnaire/ZonesCirculation";
import IntermediaireDetails from "../pages/intermediaires/IntermediaireDetails";
import IntermediairesList from "../pages/intermediaires/IntermediairesList";
import UsersList from "../pages/users/UsersList";
import Stocks from "../pages/module_gestion_attestation/pool/stocks/Stocks";
import StockDetails from "../pages/module_gestion_attestation/pool/stocks/StockDetails";
import RequestsList from "../pages/module_gestion_attestation/pool/requests/RequestsList";
import RequestsDetails from "../pages/module_gestion_attestation/pool/requests/RequestsDetails";
import DirectivesTarificationsList from "../pages/module_production/pool/parametres/tarification/directives_tarifications/DirectivesTarificationsList";
import DirectivesTarificationsDetails from "../pages/module_production/pool/parametres/tarification/directives_tarifications/DirectivesTarificationsDetails";
import InsurancesDurationListPage from "../pages/module_production/pool/parametres/tarification/insurances_durations/InsurancesDurationListPage";
import GarantiesListPage from "../pages/module_production/pool/parametres/tarification/liste_garanties/GarantiesListPage";
import GarantiesListDetails from "../pages/module_production/pool/parametres/tarification/liste_garanties/GarantiesListDetails";
import PolicesList from "../pages/module_production/polices/PolicesList";
import PoliceDetails from "../pages/module_production/polices/PoliceDetails";
import ConnexionLogs from "../pages/histoire/ConnexionLogs";
import JournalLogs from "../pages/histoire/JournalLogs";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import ProductionDashboard from "../pages/dashboard/ProductionDashboard";
import ProductionPoolDashboard from "../pages/dashboard/ProductionPoolDashboard";
import AttestationPoolDashboard from "../pages/dashboard/AttestationPoolDashboard";
import SouscripteurDetails from "../pages/souscripteurs/SouscripteurDetails";
import UserDetails from "../pages/users/UserDetails";
import GroupsPermissionsList from "../pages/configuration_permissions/GroupsPermissionsList";
import PermissionWrapper from "../components/PermissionWrapper";
import AccessDeniedPage from "../components/AccessDeniedPage";

function PoolRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="administration/compagnies/*">
          <Route
            index
            element={
              <PermissionWrapper
                requiredPermissions={["pool.administration.company.list"]}
                fallback={
                  <AccessDeniedPage resourceName="Liste des compagnies" />
                }
              >
                <CompagniesList />
              </PermissionWrapper>
            }
          />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route
            index
            element={
              <PermissionWrapper
                requiredPermissions={["pool.administration.users.list"]}
                fallback={
                  <AccessDeniedPage resourceName="Liste des utilisateurs" />
                }
              >
                <UsersList />
              </PermissionWrapper>
            }
          />
          <Route path="details/:id" element={<UserDetails />} />
        </Route>
        <Route path="administration/intermediaires/*">
          <Route
            index
            element={
              <PermissionWrapper
                requiredPermissions={["pool.administration.intermediaire.list"]}
                fallback={
                  <AccessDeniedPage resourceName="Liste des intermédiaires" />
                }
              >
                <IntermediairesList />
              </PermissionWrapper>
            }
          />
          <Route path="details/:uuid" element={<IntermediaireDetails />} />
        </Route>
        <Route path="dictionnaire/*">
          <Route path="categories-contrats" element={<CategoriesContrats />} />
          <Route path="sources-energies" element={<SourcesEnergies />} />
          <Route path="zones-circulation" element={<ZonesCirculation />} />
          <Route path="pays" element={<CountriesPage />} />
          <Route path="civilites" element={<CivilitiesPage />} />
          <Route path="professions" element={<JobsPage />} />
          <Route path="car-brands" element={<CarBrandsPage />} />
          <Route path="car-types" element={<CarTypesPage />} />
          <Route
            path="pieces-officielles"
            element={<PiecesOfficiellesPage />}
          />
        </Route>
        <Route path="stocks/*">
          <Route path="dashboard/*">
            <Route index element={<AttestationPoolDashboard />} />
          </Route>

          <Route
            index
            element={
              <PermissionWrapper
                requiredPermissions={["pool.attestation.orders.view"]}
                fallback={
                  <AccessDeniedPage resourceName="la gestion des commandes" />
                }
              >
                <Stocks />
              </PermissionWrapper>
            }
          />
          <Route path="details/:uuid" element={<StockDetails />} />
          <Route path="demandes/*">
            <Route
              index
              element={
                <PermissionWrapper
                  requiredPermissions={["pool.attestation.requests.view"]}
                  fallback={
                    <AccessDeniedPage resourceName="la gestion des demandes" />
                  }
                >
                  <RequestsList />
                </PermissionWrapper>
              }
            />
            <Route
              path="details/:uuid"
              element={
                <PermissionWrapper
                  requiredPermissions={["pool.attestation.requests.details"]}
                  fallback={
                    <AccessDeniedPage resourceName="détails d'une demande" />
                  }
                >
                  <RequestsDetails />
                </PermissionWrapper>
              }
            />
          </Route>
        </Route>

        <Route path="journalisation/*">
          <Route path="auth-logs" element={<ConnexionLogs />} />
          <Route path="activity-logs" element={<JournalLogs />} />
        </Route>

        <Route path="production/*">
          <Route path="dashboard/*">
            <Route index element={<ProductionPoolDashboard />} />
          </Route>

          <Route path="souscripteurs/*">
            <Route index element={<SouscripteursList />} />
            <Route path="details/:uuid" element={<SouscripteurDetails />} />
          </Route>
          <Route path="parametres-tarification/*">
            <Route path="directives/*">
              <Route index element={<DirectivesTarificationsList />}></Route>
              <Route
                path="details/:uuid"
                element={<DirectivesTarificationsDetails />}
              />
            </Route>
            <Route
              path="durees-assurances"
              element={<InsurancesDurationListPage />}
            />
            <Route path="liste-garanties/*">
              <Route index element={<GarantiesListPage />} />
              <Route path=":uuid" element={<GarantiesListDetails />} />
            </Route>
          </Route>
          <Route path="polices/*">
            <Route index element={<PolicesList />} />
            <Route path="details/:uuid" element={<PoliceDetails />} />
          </Route>
        </Route>
        <Route path="configuration-permissions/*">
          <Route
            path="groupes-permissions"
            element={<GroupsPermissionsList />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default PoolRoutes;
