import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import carteRoseTemplate from "../../assets/images/templates/carte_rose_template.png";
import dayjs from "dayjs";

function CarteRoseModal({ open = false, onClose = () => {}, data }) {
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!open) return; // Ne rien faire si la modale n'est pas ouverte

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();

    // Réinitialisation du canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    image.src = carteRoseTemplate;
    image.onload = () => {
      const maxWidth = 800;
      let width = image.width;
      let height = image.height;

      if (width > maxWidth) {
        const scale = maxWidth / width;
        width = maxWidth;
        height = height * scale;
      }

      setImgSize({ width, height });
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, width, height);
      ctx.font = "18px Times New Roman";
      ctx.fillStyle = "black";

      ctx.fillText(
        data.souscripteur_assure?.assures?.nom_assure || "",
        width * 0.1 - 50,
        height * 0.27
      );

      ctx.fillText(
        data.souscripteur_assure?.assures?.address_assure	 || "",
        width * 0.1 - 50,
        height * 0.33
      );

      ctx.fillText(
        data.souscription_identite_vehicule?.immatriculation_vehicule || "",
        width * 0.19,
        height * 0.44
      );

      ctx.fillText(
        data.souscription_origine?.compagnies?.denomination || "",
        width * 0.1 - 50,
        height * 0.6
      );
      ctx.fillText(
        data.souscription_origine?.compagnies?.siege_social || "",
        width * 0.1 - 50,
        height * 0.66
      );

      ctx.fillText(4, width * 0.18, height * 0.88);
      //section 2
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_effet).format("DD/MM/YYYY") ||
          "",
        width * 0.45,
        height * 0.16
      );
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_echeance).format(
          "DD/MM/YYYY"
        ) || "",
        width * 0.65,
        height * 0.16
      );

      ctx.fillText(
        data.souscription_polices?.numero_police || "",
        width * 0.62,
        height * 0.22
      );

      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_effet).format("DD/MM/YYYY") ||
          "",
        width * 0.55,
        height * 0.28
      );
      ctx.fillText(
        dayjs(data.conditions_particulieres?.date_echeance).format(
          "DD/MM/YYYY"
        ) || "",
        width * 0.7,
        height * 0.28
      );

      ctx.fillText(
        data.souscription_identite_vehicule?.marque_vehicule +
          " | " +
          data.souscription_identite_vehicule?.type_vehicule || "",
        width * 0.45,
        height * 0.38
      );

      ctx.fillText(
        data.souscription_identite_vehicule?.numero_chassis || "",
        width * 0.45,
        height * 0.49
      );
    };

    return () => {
      // Nettoyage lorsque la modale est fermée
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [data, open]);

  return (
    <Modal open={open} onClose={onClose} closeIcon size="">
      <Modal.Header>Prévisualisation de la carte rose</Modal.Header>
      <Modal.Content
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#2b2a33",
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            maxWidth: "100%",
            width: imgSize.width,
            height: imgSize.height,
            border: "1px solid #000",
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button size="tiny" onClick={onClose}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

CarteRoseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default CarteRoseModal;
