import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as companyService from "../../services/comagniesService";
import * as groupsPermissionsService from "../../services/configuration_permissions/groupsPermissionsService";
import * as intermediaireService from "../../services/intermediairesService";
import { useParams } from "react-router-dom";

const validationSchema = yup.object().shape({
  first_name: yup.string().max(150).required(),
  last_name: yup.string().max(150).required(),
  email: yup.string().email().trim().required(),
  password: yup.string().trim().min(8).max(60),
  phone: yup.string().min(8).max(16).required(),
  active: yup.boolean(),
  idgroup_permission: yup.string(),
});
//validationSchema.concat(yup.object({ password: yup.string().required() }));

function CompanyAgencyUserFormModal({
  callback = () => {},
  open = false,
  type = null,
  setOpen = () => {},
}) {
  const params = useParams();
  const [groupsPermissions, setGroupPermissions] = useState([]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone: "",
      active: true,
      idgroup_permission: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (type === "intermediaire") {
        intermediaireService
          .createNewUser({
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            email: formik.values.email,
            password: formik.values.password,
            phone: formik.values.phone,
            intermediaire_uuid: params?.uuid,
            idgroup_permission: values.idgroup_permission,
            permissions: getGroupPermissionPermissions(),
          })
          .then(
            (response) => {
              toast(`Utilisateur ajouté avec succès.`, {
                type: "success",
                theme: "colored",
              });
              handleReset();
              callback();
              setOpen(false);
            },
            (reason) => {
              toast(`${reason?.response?.data || reason?.message}`, {
                type: "error",
                theme: "colored",
              });
            }
          )
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        companyService
          .createNewUser({
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            email: formik.values.email,
            password: formik.values.password,
            phone: formik.values.phone,
            idgroup_permission: values.idgroup_permission,
            permissions: getGroupPermissionPermissions(),
          })
          .then(
            (response) => {
              toast(`Utilisateur ajouté avec succès.`, {
                type: "success",
                theme: "colored",
              });
              handleReset();
              callback();
              setOpen(false);
            },
            (reason) => {
              toast(`${reason?.response?.data || reason?.message}`, {
                type: "error",
                theme: "colored",
              });
            }
          )
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const getGroupPermissionPermissions = () => {
    let permissions = {};
    if (values.idgroup_permission) {
      let group_permission = groupsPermissions.find(
        (item) => item?.idgroup_permissions == values.idgroup_permission
      );
      if (group_permission) {
        permissions = group_permission.permissions;
      }
    }
    return permissions;
  };

  const fetchGroupsPermissions = async () => {
    try {
      const response = await groupsPermissionsService.fetchAll(
        null,
        null,
        null,
        type == "intermediaire" ? "broker" : "company"
      );
      let data = response.data?.groupPermissions;
      console.log(data);
      if (response.status === 200 && data) {
        if (Array.isArray(data)) setGroupPermissions(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchGroupsPermissions();
  }, []);

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        {type === "intermediaire"
          ? "Ajouter un utilisateur "
          : " Ajouter un utilisateur à la compagnie"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"last_name" in errors}>
              <label>Nom</label>
              <Input
                value={values.last_name}
                onChange={handleChange("last_name")}
                onBlur={handleBlur("last_name")}
              />
              <small className="field-error">{errors.last_name}</small>
            </Form.Field>
            <Form.Field required error={"first_name" in errors}>
              <label>Prénom</label>
              <Input
                value={values.first_name}
                onChange={handleChange("first_name")}
                onBlur={handleBlur("first_name")}
              />
              <small className="field-error">{errors.first_name}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"phone" in errors}>
              <label>Téléphone</label>
              <Input
                value={values.phone}
                onChange={handleChange("phone")}
                onBlur={handleBlur("phone")}
              />
              <small className="field-error">{errors.phone}</small>
            </Form.Field>
            <Form.Field required error={"email" in errors}>
              <label>Email</label>
              <Input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
              />
              <small className="field-error">{errors.email}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"password" in errors}>
              <label>Mot de passe</label>
              <Input
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                name="password"
                action={{
                  icon: "magic",
                  onClick: (e) => {
                    e.preventDefault();
                    const randomPassword = Math.random().toString(36).slice(-8);
                    setFieldValue("password", randomPassword, false);
                  },
                }}
              />
              <small className="field-error">{errors.password}</small>
            </Form.Field>
            {/*<Form.Field error={"active" in errors}>
              <Form.Checkbox
                onChange={(e, data) => setFieldValue("active", data.checked)}
                label="Compte actif"
                defaultChecked
                checked={values.active}
              />
              <small className="field-error">{errors.active}</small>
            </Form.Field> */}
          </Form.Group>

          <Form.Group widths={"equal"}>
            <Form.Field required error={"idgroup_permission" in errors}>
              <label>Groupe de permission</label>
              <Form.Select
                fluid
                scrolling
                value={values.idgroup_permission}
                options={[{ key: "", value: "", text: "Choisir" }].concat(
                  groupsPermissions.map((item) => ({
                    key: item?.idgroup_permissions,
                    value: item?.idgroup_permissions,
                    text: item?.name,
                  }))
                )}
                onChange={(e, data) =>
                  setFieldValue("idgroup_permission", data.value)
                }
                placeholder="Choisr..."
              />
              <small className="field-error">{errors.idgroup_permission}</small>
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyAgencyUserFormModal;
