import { api } from "../axios/api";

export const fetchAll = async ({ page, search } = {}) => {
  return api.get(`dictionnaire/car-types`, { params: { page, search } });
};
export const fetchOne = async (id) => {
  return api.get(`dictionnaire/car-types/${id}`);
};
export const create = async (data) => {
  return api.post(`dictionnaire/car-types`, data);
};

export const update = async (id, data) => {
  return api.put(`dictionnaire/car-types/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`dictionnaire/car-types/${id}`);
};
