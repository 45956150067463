import React from "react";
import { Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import numeral from "numeral";

function PreviewContent({ payment, styles }) {
  return (
    <View style={styles.section}>
      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        Reçu{" "}
        {payment.bills.souscription_reimbursements
          ? " de Décaissement"
          : " d'Encaissement"}
      </Text>

      <View>
        <View style={styles.section}>
          <View style={[styles.section, { margin: 0, padding: 0 }]}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: "bold",
                marginBottom: 5,
              }}
            >
              Informations sur l'agence/compagnie
            </Text>
            {payment.bills.avenants.souscription.souscription_origine
              .intermediaires && (
              <Text>
                Intermédiaire:{" "}
                {
                  payment.bills.avenants.souscription.souscription_origine
                    .intermediaires.raison_sociale
                }
              </Text>
            )}
            {payment.bills.avenants.souscription.souscription_origine
              .compagnies && (
              <Text>
                Compagnie:{" "}
                {
                  payment.bills.avenants.souscription.souscription_origine
                    .compagnies.denomination
                }
              </Text>
            )}
            {payment.bills.avenants.souscription.souscription_origine
              .compagnies_agencies && (
              <Text>
                Agence de compagnie:{" "}
                {
                  payment.bills.avenants.souscription.souscription_origine
                    .compagnies_agencies.denomination
                }
              </Text>
            )}
            {payment.bills.avenants.souscription.souscription_origine
              .intermediaires_agencies && (
              <Text>
                Agence d'intermédiaire:{" "}
                {
                  payment.bills.avenants.souscription.souscription_origine
                    .intermediaires_agencies.nom
                }
              </Text>
            )}
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={[styles.section, { width: "50%" }]}>
            <Text style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {payment.bills.souscription_reimbursements
                ? "Décaissement"
                : "Encaissement"}{" "}
              N°: {payment.code}
            </Text>
            <Text>
              Date: {dayjs(payment.created_at).format("DD/MM/YYYY HH:mm")}
            </Text>
            <Text>
              Caisse: {payment.users.first_name} {payment.users.last_name}
            </Text>
          </View>
          <View style={[styles.section, { width: "50%", textAlign: "right" }]}>
            <Text>Client:</Text>
            <Text>
              {payment.bills.avenants.souscription.souscripteurs
                .groupe_souscripteur === "PARTICULIER"
                ? `${payment.bills.avenants.souscription.souscripteurs.last_name} ${payment.bills.avenants.souscription.souscripteurs.first_name}`
                : payment.bills.avenants.souscription.souscription_origine
                    .compagnies.denomination}
            </Text>
            <Text>
              # {payment.bills.avenants.souscription.souscripteurs.num}
            </Text>
            <Text>
              {payment.bills.avenants.souscription.souscripteurs.adresse}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
            <View style={[styles.tableCol, { width: "40%", padding: 5 }]}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%", padding: 5 }]}>
              <Text style={styles.tableCell}>Quantité</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%", padding: 5 }]}>
              <Text style={styles.tableCell}>Prix</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%", padding: 5 }]}>
              <Text style={styles.tableCell}>Montant</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "40%", padding: 5 }]}>
              <Text style={styles.tableCell}>
                {payment.bills.avenants.type_avenant}
              </Text>
            </View>
            <View style={[styles.tableCol, { width: "20%", padding: 5 }]}>
              <Text style={styles.tableCell}>1</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%", padding: 5 }]}>
              <Text style={styles.tableCell}>
                {payment.bills.souscription_reimbursements
                  ? numeral(
                      Number(payment.bills.souscription_reimbursements?.amount) + 
                      (payment?.bills?.avenants?.meta_data?.frais ?
                      Number(payment?.bills?.avenants?.meta_data?.frais) :
                      Number(payment.bills.amount))
                    ).format("")
                  : numeral(payment.bills.amount).format("")}{" "}
                FCFA
              </Text>
            </View>
            <View style={[styles.tableCol, { width: "20%", padding: 5 }]}>
              <Text style={styles.tableCell}>
                {payment.bills.souscription_reimbursements
                  ? numeral(
                      Number(payment.bills.souscription_reimbursements?.amount) + 
                      (payment?.bills?.avenants?.meta_data?.frais ?
                      Number(payment?.bills?.avenants?.meta_data?.frais) :
                      Number(payment.bills.amount))
                    ).format("")
                  : numeral(payment.bills.amount).format("")}{" "}
                FCFA
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={[styles.section, { width: "30%" }]}></View>
        <View
          style={[styles.table, { width: "70%", marginTop: 8, borderWidth: 0 }]}
        >
          {payment.bills.souscription_reimbursements ? (
            <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
              <View style={[styles.tableCol, { width: "50%", padding: 5 }]}>
                <Text style={styles.tableCell}>Frais</Text>
              </View>
              <View style={[styles.tableCol, { width: "50%", padding: 5 }]}>
                <Text style={styles.tableCell}>
                  - {
                    numeral(payment?.bills?.avenants?.meta_data?.frais ?
                    payment?.bills?.avenants?.meta_data?.frais :
                    payment.bills.amount).format("")
                  }{" "} 
                  FCFA
                </Text>
              </View>
            </View>
          ) : null}
          <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
            <View style={[styles.tableCol, { width: "50%", padding: 5 }]}>
              <Text style={styles.tableCell}>
                Montant{" "}
                {payment.bills.souscription_reimbursements
                  ? "décaissé"
                  : "encaissé"}
              </Text>
            </View>
            <View style={[styles.tableCol, { width: "50%", padding: 5 }]}>
              <Text style={styles.tableCell}>
                {payment.bills.souscription_reimbursements
                  ? numeral(
                    Number(payment.bills.souscription_reimbursements
                    ?.amount)
                    ).format("")
                  : numeral(payment.bills.amount).format("")}{" "}
                FCFA
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={[
          styles.section,
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          },
        ]}
      >
        <Text style={{ fontSize: 14, fontWeight: "bold" }}>
          (Pour la Compagnie)
        </Text>
        <Text style={{ fontSize: 14, fontWeight: "bold" }}>
          (Pour l'assuré)
        </Text>
      </View>
    </View>
  );
}

export default PreviewContent;
