import React, { Fragment } from "react";
import { Chart as ChartJS, Tooltip, Legend, Title, ArcElement } from "chart.js";
import { Grid, Header, Segment } from "semantic-ui-react";
import { Pie } from "react-chartjs-2";
import dayjs from "dayjs";
import styles from "../../styles/dashboard.module.css";
import DaylyProductionPieChart from "../../components/charts/DaylyProductionPieChart";
import DaylyProductionBarChart from "../../components/charts/DaylyProductionBarChart";
import DaylyProductionLineChart from "../../components/charts/DaylyProductionLineChart";

// Register chart components
ChartJS.register(ArcElement, Title, Tooltip, Legend);

function ChartRow({
  header_label = null,
  header_value = null,
  chart_type = null,
  data_type = null,
  pie_chart_data_type = null,
  data = null,
  start_date = null,
  end_date = null,
  legend = false,
}) {
  // Chart options

  return (
    <Segment>
      <Grid className={styles.chartRow}>
        <div className={styles.chartRowHeader}>
          <label className={styles.chartRowHeaderLabel1}>{header_label}</label>
          <span className={styles.chartRowHeaderLabel2}>{header_value}</span>
        </div>
        <div className={styles.chartRowContent}>
          <div
            className={
              pie_chart_data_type
                ? styles.chartRowContentC1
                : styles.chartRowContentU
            }
          >
            {chart_type == "bar" ? (
              <DaylyProductionBarChart
                statisticsData={data}
                data_type={data_type}
                legend={legend}
              />
            ) : chart_type == "pie" ? (
              <DaylyProductionPieChart
                statisticsData={data}
                data_type={pie_chart_data_type}
              />
            ) : chart_type == "line" ? (
              <DaylyProductionLineChart
                statisticsData={data}
                data_type={data_type}
              />
            ) : null}
          </div>
          {pie_chart_data_type ? (
            <div className={styles.chartRowContentC2}>
              <DaylyProductionPieChart
                statisticsData={data}
                data_type={pie_chart_data_type}
              />
            </div>
          ) : null}
        </div>
      </Grid>
    </Segment>
  );
}

export default ChartRow;
